
import { Container, Nav, Navbar } from "react-bootstrap";
import "./header.scss";
import { useContext, useEffect, useState } from "react";
import UtilityService from "../../utility/utility.service";
import GAConfig from "../../ga.config";
import { useNavigate } from "react-router-dom";
import appUrl from "../../app-url";
import LOGO_IMG from "../../assets/images/logo-jobhack.png";
import translate from "../../assets/json/th.json";


const HeaderComponent = () => {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
 
    const handleToggle = () => {
        setExpanded(!expanded);
    };

 

   
    const clickMenu = (source: string, path) => {
        UtilityService().clickSendEvent("menu_"+source,GAConfig.event.click)
        window.open(path, "_self");
    }

    return (
        <>
            <Navbar expand="lg" expanded={expanded} className='header'>
                <Container className="header-grid-control">
                    <div className="header-logo-control">
                        <img src={LOGO_IMG} className="header-logo-img"></img>
                    </div>

                    <div className="header-menu-center-control">
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('home', '/')}>{translate.header.home}</Nav.Link>
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('resume_builder', appUrl.url.builder)}>{translate.header.resume_builder}</Nav.Link>
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('resume_checker', appUrl.url.checker)}>{translate.header.resume_checker}</Nav.Link>
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('interview_ai', appUrl.url.interview_ai)}>{translate.header.interview_ai}</Nav.Link>
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('one_profile', appUrl.url.login)}>สร้างโปรไฟล์</Nav.Link>
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('jobboard', appUrl.url.jobboard)}>{translate.header.find_jobs}</Nav.Link>
                        <div className="header-divider">

                        </div>
                        {/* <LanguageSwitcher languages={['en', 'th']} /> */}
                        {/* <span className={
                            languageService.getLanguage() == 'TH' ? "header-menu-lang-active" : "header-menu-lang"
                        }>TH</span>
                        <span className={
                            languageService.getLanguage() == 'EN' ? "header-menu-lang-active" : "header-menu-lang"
                        }>EN</span> */}
                    </div>

                    <div className="header-menu-nav-mobile-control">
                        <Navbar.Toggle aria-controls="navbar" onClick={handleToggle} />
                    </div>

                </Container>



                {
                    expanded && <div className="header-navbar-side" >
                       

                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', '/')}>{translate.header.home}</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', appUrl.url.builder)}>{translate.header.resume_builder}</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', appUrl.url.checker)}>{translate.header.resume_checker}</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', appUrl.url.interview_ai)}>{translate.header.interview_ai}</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', appUrl.url.dating)}>{translate.header.resume_dating}</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', appUrl.url.jobboard)}>{translate.header.find_jobs}</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', "https://scoutout.co/terms-th/")}>{translate.footer.terms}</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', "https://scoutout.co/privacy-th/")}>{translate.footer.privacy}</Nav.Link>
                        <div className="header-divider-mobile"></div>
                        {/* <LanguageSwitcher languages={['en', 'th']} /> */}
                    </div>
                }
            </Navbar>
        </>
    )
}

export default HeaderComponent;