import "./landing.scss";


import RESUME_CHECKER_IMG from "../../assets/images/resume-checker-img.png";
import RESUME_CHECKER_LOGO from "../../assets/images/resume-checker-logo.png";

import INTERVIEW_AI_LOGO from "../../assets/images/interview-ai-logo.png";
import INTERVIEW_AI_IMG from "../../assets/images/interview-ai-img.png";

import RESUME_DATING_LOGO from "../../assets/images/resume-dating-logo.png";
import RESUME_DATING_IMG from "../../assets/images/resume-dating-img.png";
import RESUME_DATING_TEXT from "../../assets/images/resume-dating-text.png";
import { Button, Container } from "react-bootstrap";
import HeaderComponent from "../header/header";
import UtilityService from "../../utility/utility.service";
import appUrl from "../../app-url";
import translate from "../../assets/json/th.json";

const LandingComponent = () => {
    const clickurl = (url, name) => {
        UtilityService().clickSendEvent("" + name, "click");
        window.open(url, "_blank");
    }
  

    return (
        <>
            <div className="landing">
                <HeaderComponent></HeaderComponent>
                <div className="section-1-control">
                    <h1 className="section-1-title" dangerouslySetInnerHTML={{__html : translate.desc.section_1}}>
                        
                        </h1>
                    <span className="section-1-desc">{translate.desc.section_1_1}</span>
                </div>
                <div className="section-2-control">
                    <div className="section-2-container">
                        <div className="section-2-text-control">
                            <h1 className="section-2-title" ><span dangerouslySetInnerHTML={{__html:translate.desc.section_2}}></span> <span className="section-2-title-special">{translate.desc.section_2_1}</span></h1>
                            <span className="section-2-desc">{translate.desc.section_2_2}</span>
                            <Button className="section-2-button" onClick={() => clickurl(appUrl.url.builder, "resume_builder")}>{translate.desc.section_2_3}</Button>
                        </div>
                    </div>
                </div>
                <div className="section-3-control">
                    <Container className="section-3-container-control">
                        <div className="section-3-left-control">
                            <img src={RESUME_CHECKER_LOGO} className="section-3-logo-img"></img>
                            <h2 className="section-3-title"dangerouslySetInnerHTML={{__html: translate.desc.section_3}}></h2>
                            <span className="section-3-desc">{translate.desc.section_3_1}</span>
                            <Button className="section-3-button" 
                            onClick={() => clickurl(appUrl.url.checker, "resume_checker")}>{translate.desc.section_3_2}</Button>
                        </div>
                        <div className="section-3-right-control">
                            <img src={RESUME_CHECKER_IMG} className="section-3-right-img"></img>
                        </div>
                    </Container>
                </div>
                <div className="section-4-control">
                    <Container className="section-4-container-control">
                        <div className="section-4-right-control">
                            <img src={INTERVIEW_AI_IMG} className="section-4-right-img"></img>
                        </div>

                        <div className="section-4-left-control">
                            <img src={INTERVIEW_AI_LOGO} className="section-4-logo-img"></img>
                            <h2 className="section-4-title">{translate.desc.section_4}</h2>
                            <span className="section-4-desc">{translate.desc.section_4_1}</span>
                            <Button className="section-4-button"  onClick={() => clickurl(appUrl.url.interview_ai, "interview_ai")}>{translate.desc.section_4_2}</Button>
                        </div>

                    </Container>
                </div>
                <div className="section-5-control">
                    <Container className="section-5-container-control">


                        <div className="section-5-left-control">
                            <img src={RESUME_DATING_LOGO} className="section-5-logo-img"></img>
                            <img src={RESUME_DATING_TEXT} className="section-5-title"></img>
                            <span className="section-5-desc">{translate.desc.section_5}</span>
                            <Button className="section-5-button"  onClick={() => clickurl(appUrl.url.dating, "resume_dating")}>{translate.desc.section_5_1}</Button>
                        </div>
                        <div className="section-5-right-control">
                            <img src={RESUME_DATING_IMG} className="section-5-right-img"></img>
                        </div>
                    </Container>
                </div>
                {/* <FooterComponent></FooterComponent> */}
            </div>
        </>
    )
}

export default LandingComponent;