// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
import config from './config';
import App from './App';
const env = process.env.REACT_APP_ENVIRONMENT; // Get the current environment
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'one_profile_production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}
root.render(
    <App />
);
reportWebVitals();
ReactGA.initialize([
  {
    trackingId: config.ga_4,
  }
]);
