const appUrl = {
    url: {
        dating: "https://resume-dating.jobhack.co?utm_source=RESUME_LANDING",
        checker: "https://resume-checker.jobhack.co/?utm_source=RESUME_LANDING",
        interview_ai: "https://interview.jobhack.co?utm_source=RESUME_LANDING",
        builder: "https://app.oneprofile.me/login",
        login: "https://app.oneprofile.me/login",
        jobboard: "https://app.jobhack.co?utm_source=RESUME_LANDING",
        terms: "https://scoutout.co/th/terms-th/?utm_source=RESUME_LANDING",
        privacy: "https://scoutout.co/th/privacy-th?utm_source=RESUME_LANDING",
        tel: "tel:020382639",
        email: "mailto:customerservice@scoutout.net",
        fb: "https://www.facebook.com/ScoutOutLimited/",
        line: "https://page.line.me/wlq0077m?openQrModal=true",
        yt: "https://www.youtube.com/channel/UCQ8YAzjTz8H6hDofWUkC4Xg",
        x: "https://twitter.com/ScoutOutLimited",
        lk: "https://www.linkedin.com/company/scoutout-limited/"
    }
}


export default {
    ...appUrl
};